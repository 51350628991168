
















































import {Component, Vue} from "vue-property-decorator";
import Project from "@/models/Project";
import ProjectService from "@/services/ProjectService";

@Component
export default class ProjectView extends Vue {
    loading: boolean = false
    projects: Project[] = []

    created() {
        ProjectService.getProjects(this, this.projects)
    }
}
